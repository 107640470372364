// common
export const RESET_STATE = 'reset'

// --
export const SET_CROP = 'set'
export const SET_CROP_DIRTY = 'setDirty'
export const SET_CROP_PROPS = 'setProps'

export const ADD_CROP_MATURITY = 'addMaturity'
export const REMOVE_CROP_MATURITY = 'removeMaturity'
export const SET_CROP_MATURITY = 'setMaturity'

export const ADD_CROP_BRAND = 'addBrand'
export const REMOVE_CROP_BRAND = 'removeBrand'
export const SET_CROP_BRAND = 'setBrand'

export const ADD_CROP_TEST = 'addTest'
export const REMOVE_CROP_TEST = 'removeTest'
export const SET_CROP_TEST = 'setTest'

export const ADD_CROP_PRETEST = 'addPreTest'
export const REMOVE_CROP_PRETEST = 'removePreTest'
export const SET_CROP_PRETEST = 'setPreTest'

export const ADD_CROP_LINETEST = 'addLineTest'
export const REMOVE_CROP_LINETEST = 'removeLineTest'
export const SET_CROP_LINETEST = 'setLineTest'

export const ADD_CROP_DEMO = 'addDemo'
export const REMOVE_CROP_DEMO = 'removeDemo'
export const SET_CROP_DEMO = 'setDemo'

// UI reset (Bereich zurücksetzen)
export const RESET_OWNERSHIP = 'resetOwnership'
export const RESET_CULTUREUSAGE = 'resetCultureUsage'
