import Vue from 'vue'

import { PUSH, DISMISS, DISMISS_ALL, RESET_STATE } from './mutations.type'
import { cloneDeep } from 'lodash'

const initialState = { errors: [] }

export const state = cloneDeep(initialState)

export const actions = {}

export const mutations = {
  [PUSH] (state, unClearedError) {
    state.errors.push(unClearedError)
  },
  [DISMISS] (state, id) {
    state.errors.splice(state.errors.findIndex(e => e.id === id), 1)
  },
  [DISMISS_ALL] (state) {
    state.errors = []
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, initialState[f])
    }
  }
}

const getters = {
  errors (state) {
    return state.errors
  },
  lastUnclearedError (state) {
    return state.errors.reverse().find(e => !e.cleared)
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
