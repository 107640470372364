import 'nprogress/nprogress.css'

import NProgress from 'nprogress'
import axios from 'axios'

export function loadProgressBar (config, instance = axios) {
  let requestsCounter = 0

  const setupStartProgress = () => {
    instance.interceptors.request.use(config => {
      if ((requestsCounter++) === 0) {
        NProgress.start()
      }
      return config
    })
  }

  const setupUpdateProgress = () => {
    const update = () => NProgress.inc()
    instance.defaults.onDownloadProgress = update
    instance.defaults.onUploadProgress = update
  }

  const setupStopProgress = () => {
    const responseFunc = response => {
      if ((--requestsCounter) === 0) {
        NProgress.done(true)
      }
      return response
    }

    const errorFunc = error => {
      if ((--requestsCounter) === 0) {
        NProgress.done(true)
      }
      return Promise.reject(error)
    }

    instance.interceptors.response.use(responseFunc, errorFunc)
  }

  NProgress.configure(config)
  setupStartProgress()
  setupUpdateProgress()
  setupStopProgress()
}
