import Vue from 'vue'
import Vuex from 'vuex'

import firms from '@/store/modules/firms'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import errors from '@/store/modules/errors'
import crop from '@/store/modules/crop'
import drawer from '@/store/modules/drawer'
import contract from '@/store/modules/contract'

export const RESET_STATE = 'reset'

Vue.use(Vuex)

const modules = {
  firms,
  contract,
  drawer,
  auth,
  user,
  errors,
  crop
}

export default new Vuex.Store({
  modules,
  actions: {
    [RESET_STATE] ({ commit }) {
      Vue.$log.info('RESET_STATE for all modules')
      Object.entries(modules)
        .forEach(function ([moduleName, module]) {
          Vue.$log.debug(moduleName)
          module.mutations[RESET_STATE](module.state)
        })
    }
  }
})
