import Vue from 'vue'
import { trim } from 'lodash'

const trimEventValue = function (e) {
  const oldValue = e.target.value
  e.target.value = trim(e.target.value)

  if (oldValue !== e.target.value) this.$emit('input', e.target.value)
}

Vue.directive('trim-on-blur', {
  bind: function (el, binding, vnode) {
    el.addEventListener('blur', trimEventValue.bind(vnode.context), {
      passive: true
    })
  },
  unbind: function (el) {
    el.removeEventListener('blur', trimEventValue, { passive: true })
  }
})
