import store from '@/store'
import {
  GenericError,
  ResponseError,
  NotFoundError,
  BadEntitiyError
} from '@/common/utils/error.types.js'

export function genericAxiosErrorHandler (error) {
  let errorObject
  let status
  let ErrorClass
  if (error.response) {
    status = error.response.status
    switch (status) {
      case 404: case 410: ErrorClass = NotFoundError; break
      case 422: ErrorClass = BadEntitiyError; break
      default: ErrorClass = ResponseError; break
    }
    errorObject = new ErrorClass(
      error.response.statusText,
      error.response.data,
      error
    )
  } else if (error.request) {
    errorObject = new GenericError('unable to contact server', null, error)
  } else {
    const errorIsObject = typeof error === 'object' && error !== null
    const { message, data } = errorIsObject
      ? { message: 'some error', data: error }
      : { message: error, data: {} }
    errorObject = new GenericError(message, data, error)
  }

  if (status === 401) {
    if (store.getters['auth/isLoggedIn']) {
      store.dispatch('auth/logout', { reason: 'unknown' })
    }
  } else {
    store.commit('errors/push', errorObject)
  }

  return Promise.reject(error)
}
