import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const savedLocale = localStorage.getItem('initialLocale') || 'de'

export const i18n = new VueI18n({
  locale: savedLocale,
  fallbackLocale: 'de',
  messages: { de: {}, en: {} },
  silentTranslationWarn: true
})

export function updateLocale (newLocale) {
  i18n.locale = newLocale
  localStorage.setItem('initialLocale', newLocale)
}
