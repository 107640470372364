<template lang="pug">
div
  TheHeader
  b-container#content(:fluid="$route.meta.requiresFluid")
    main.loading-fade(
      :class="{ loading: loadingClass }",
      @transitionend="handleTransitionEnd($event)"
    )
      b-row
        b-col
          router-view(:isLoading.sync="isLoading")
        b-col(cols=12, lg=3, v-if="!$route.meta.noSidebar")
          router-view(name="sidebar")
</template>

<script>
import TheHeader from '@/components/common/TheHeader.vue'

export default {
  components: {
    TheHeader
  },
  data () {
    return {
      isLoading: false,
      loadingClass: false
    }
  },
  watch: {
    isLoading (l) {
      if (l) {
        this.loadingClass = true
      }
    }
  },
  methods: {
    handleTransitionEnd (ev) {
      if (ev.propertyName !== 'opacity') return
      this.$nextTick(() => {
        this.loadingClass = false
      })
    }
  }
}
</script>
