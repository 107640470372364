import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import { DEV_MODE } from '@/config.js'

const options = {
  isEnabled: true,
  logLevel: DEV_MODE ? 'debug' : 'info',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: DEV_MODE,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)

// as soon as logging is established

const name = process.env.VUE_APP_NAME
const branch = process.env.VUE_APP_GIT_BRANCH
const version = process.env.VUE_APP_GIT_VERSION
Vue.$log.info(`starting ${name}@${branch}-${version}`)
