// common
export const RESET_STATE = 'reset'

// --

// documents/files
export const ADD_OR_REPLACE_CONTRACT_DOCUMENT = 'addOrReplaceDocument'
export const SET_CONTRACT_DOCUMENTS_SIGNED_BY = 'setDocumentsSignedBy'
export const SET_CONTRACT_DOCUMENTS_ANNOTATION = 'setDocumentsAnnotation'

// contract subjects
export const ADD_CONTRACT_SUBJECT = 'addSubject'
export const REMOVE_CONTRACT_SUBJECT = 'removeSubject'
export const SET_CONTRACT_SUBJECT = 'setSubject'

// contract
export const SET_CONTRACT_DIRTY = 'setDirty'
export const SET_CONTRACT = 'set'
export const SET_CONTRACT_PROPS = 'setProps'
export const SET_CONTRACT_TYPE = 'setType'

// UI reset (Bereich zurücksetzen)
export const RESET_PRIMARY = 'resetPrimary'
export const RESET_SECONDARY = 'resetSecondary'
export const RESET_TITLE = 'resetTitle'
export const RESET_RUNTIME = 'resetRuntime'
export const RESET_DOCUMENTS = 'resetDocuments'
