import { isPlainObject } from 'lodash'
import { loadProgressBar } from '@/common/utils/axiosProgressBar.js'

export { loadProgressBar }

export function valueToTextValueObject (v) {
  return isPlainObject(v) && 'text' in v
    ? v
    : {
        text: v,
        value: v
      }
}

export function reindexObjectArray (objects, defaultObj = {}) {
  if (objects.length === 0) objects.push({ ...defaultObj, id: 0 })
  else {
    objects.forEach((e, i) => Object.assign(e, Object.assign({ ...defaultObj }, e, { id: i })))
  }
}
