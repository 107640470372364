export default {
  watch: {
    isDirty (dirty) {
      dirty && this.$log.warn('dirty')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      this.$root
        .confirm(this.$t('confirm.route-change/discard-unsaved'))
        .then(confirmed => next(confirmed))
    } else next()
  },
  async beforeRouteUpdate (to, from, next) {
    this.$log.debug('beforeRouteUpdate')

    const wasAutoLoggedOut = to.name === 'logout' && !!to.params.reason

    if (!wasAutoLoggedOut && !to.params.newState && this.isDirty) {
      const discard = await this.$root.confirm(
        this.$t('confirm.route-change/discard-unsaved')
      )

      if (!discard) {
        next(false)
        return
      }
    }

    if (to.params.resetState) {
      this.$log.debug('beforeRouteUpdate:RESET_STATE')
      this.reset()
      if (to.params.newState) {
        this.set(to.params.newState)
      }
      this.resetData && this.resetData()
    }
    next()
  }
}
