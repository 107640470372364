import Vue from 'vue'
import store from '@/store'
import { WS_URL } from '@/config.js'
import { debounce, castArray, map, filter, isEmpty } from 'lodash'
import ActivityMessage from '@/common/api/activitybus/ActivityMessage'

export const UNKNOWN = 'EVENT_UNKNOWN'

// actually needed events:
export const VIEW = 'EVENT_VIEW'

const RETRY_INTERVAL = 5000

export default class ActivityBus {
  #connURI = ''

  #conn = null

  #handler = null

  #userId = null

  #listeners = []

  #connCounter = 0

  #keepconnected = true

  constructor (msgHandler = () => {}, url = WS_URL) {
    this.#connURI = url
    this.#handler = msgHandler
  }

  toString () {
    return `ActivityBus[(${this.#connCounter}) ${this.#connURI}?userid=${this.#userId} state:${this.state}]`
  }

  connect ({ onOpen = null, onClose = null }) {
    this.#userId = store.getters['auth/user'].id
    this.#connCounter++
    this.#conn = new WebSocket(this.#connURI + '?userId=' + this.#userId)

    this.one('open', () => {
      Vue.$log.debug('open', String(this))
      const msgHandler = ({ data }) => this.#handler(ActivityMessage.fromJson(data))
      this.on('message', msgHandler)
      this.one('close', () => this.off('message', msgHandler))

      onClose && this.one('close', () => {
        Vue.$log.debug('close', String(this))
        onClose()
      })

      onOpen && onOpen()
    })
  }

  get connected () {
    return this.#conn && this.#conn.readyState === WebSocket.OPEN
  }

  on (event, fn) {
    return this.#conn
      ? this.#conn.addEventListener(event, fn)
      : null
  }

  off (event, fn) {
    if (this.#conn) { this.#conn.removeEventListener(event, fn) }
  }

  one (event, fn, condition = () => true) {
    const once = this.on(event, ({ data }) => {
      const msg = data ? ActivityMessage.fromJson(data) : {}
      if (condition(msg)) {
        fn(msg)
        this.off(event, once)
      }
    })
  }

  connectPersistant () {
    const retry = () => {
      if (!this.#keepconnected) {
        retryDebounced.cancel()
        return
      }

      Vue.$log.warn('attempting to re-establish ActivityBus connection ...')

      this.connect({
        onOpen: () => {
          Vue.$log.info('re-established ActivityBus connection!', this.toString())
        },
        onClose: retryDebounced
      })
    }

    const retryDebounced = debounce(retry, RETRY_INTERVAL)
    this.#keepconnected = true

    Vue.$log.info('attempting to establish ActivityBus connection ...')
    this.connect({
      onOpen: () => {
        Vue.$log.info('established ActivityBus connection!', this.toString())
      },
      onClose: retry
    })
  }

  disconnect () {
    this.#keepconnected = false
    Vue.$log.warn('ActivityBus disconnecting ...')
    if (this.#conn && this.state === WebSocket.OPEN) {
      Vue.$log.debug('disconnecting', this.#conn.url)

      this.#conn.close(1000)
      this.#conn = null
    }
  }

  send (activityMessage) {
    if (this.state === WebSocket.OPEN) {
      this.#conn.send(JSON.stringify(activityMessage))
    } else {
      Vue.$log.warn('ActivityBus no connected')
    }
  }

  answer (event = UNKNOWN, modelType, modelIds = [], asker, originalEventId) {
    const answer = new ActivityMessage('ok', event, originalEventId, this.#userId, {
      to: asker,
      payload: { modelIds: castArray(modelIds), modelType, action: 'test' }
    })
    this.send(answer)
  }

  /**
   *
   *
   * @param {string} event
   * @param {string} modelType
   * @param {number[]} modelIds
   * @param {number} to
   * @param {number} timeout
   */
  async ask (event = UNKNOWN, modelType, modelIds = [], to = null, timeout = 3000) {
    const userId = this.#userId
    Vue.$log.info(modelType)

    if (!modelType) {
      throw new Error('cannot ActivityBus.ask without `modelType`')
    }

    modelIds = castArray(modelIds)
    timeout = Math.min(10000, Math.max(100, timeout)) // 100 < timeout < 10000 ms

    if (isEmpty(modelIds)) {
      // no modelIds to ask about given
      return Promise.reject(new Error())
    }

    Vue.$log.debug(`${userId} asks if ${event}? on `, modelIds)

    const question = new ActivityMessage('ok', event + '?', null, this.#userId, {
      to,
      payload: { modelIds: castArray(modelIds), modelType, action: 'test' }
    })

    const answers = Object.fromEntries(modelIds.map(id => {
      let _resolve = null
      const p = new Promise((resolve, reject) => {
        _resolve = resolve
        setTimeout(reject, 3000)
      })
      return [id, { p, resolve: _resolve }]
    }))

    Vue.$log.debug(`${userId} expects answers for `, answers)

    const answerMsgHandler = ({ data }) => {
      const maybeAnswer = ActivityMessage.fromJson(data)
      if (maybeAnswer.to === userId &&
        question.eventId === maybeAnswer.eventId &&
        question.payload.modelType === maybeAnswer.payload.modelType) {
        for (const modelId of maybeAnswer.payload.modelIds) {
          answers[modelId] && answers[modelId].resolve(maybeAnswer)
        }
      }
    }

    // register msg:handler and send
    this.on('message', answerMsgHandler)
    this.send(question)

    const settled = await Promise.allSettled(map(answers, 'p'))

    this.off('message', answerMsgHandler)

    const allRejected = settled.every(r => r.status === 'rejected')

    if (allRejected) {
      // noone answered
      Vue.$log.info(`ActivityBus.ask(${event}?) timed out after ${timeout}ms - maybe noone is there to listen`)
      return []
    }

    const fulfilled = filter(settled, ['status', 'fulfilled'])

    const values = map(fulfilled, 'value')

    return values || Promise.reject(new Error(`ActivityBus.ask(${event}?) resulted in undefined answer`))
  }

  get state () {
    return this.#conn ? this.#conn.readyState : -1
  }

  get VIEW () {
    return VIEW
  }
}
