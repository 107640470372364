import ApiService from './ApiService.js'

export default {
  get (slug) {
    return ApiService.get('firms', slug)
  },

  find (params, subresource = '') {
    return ApiService.get('firms', subresource, params)
  },

  put (payload, slug = []) {
    return ApiService.put('firms', slug, { payload })
  },

  post (payload) {
    return ApiService.post('firms', { payload })
  },

  trash (id) {
    return ApiService.trash('firms', id)
  },

  untrash (id) {
    return ApiService.untrash('firms', id)
  },

  delete (id) {
    return ApiService.delete('firms', id)
  }
}
