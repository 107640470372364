import Vue from 'vue'
import { AuthService } from '@/common/api'
import deepFreeze from '@/common/utils/deepFreeze'
import moment from 'moment'
import routes from '@/routes'
import { update } from '@/ability'

import { INIT, RENEW, REFRESH, LOGOUT } from './actions.type'
import { SET_USER, SET_PENDING, RESET_STATE } from './mutations.type'

import { get, cloneDeep, map } from 'lodash'

function updateAbilities (user) {
  const newAbilities = get(user, 'session.abilities', [])
  update(newAbilities)
}

const initialState = deepFreeze({
  currentUser: { info: {}, session: {}, dataowners: [] },
  pending: false
})

export const state = cloneDeep(initialState)

export const actions = {
  async [INIT] ({ commit, dispatch, getters }, credidentials) {
    let success = false

    commit(SET_PENDING, true)

    try {
      if (credidentials) {
        const { email, password } = credidentials
        await AuthService.login(email, password)
      }

      const { data } = await AuthService.getUser()
      commit(SET_USER, data)
      success = true
    } catch (err) {
      Vue.$log.warn(err.message)
      commit(RESET_STATE)
    } finally {
      commit(SET_PENDING, false)
    }
    return success
  },
  async [LOGOUT] ({ commit, getters }, params = {}) {
    Vue.$log.debug('logout')
    try {
      await AuthService.logout()
    } catch (err) {
      if (get(err, 'response.status') !== 401) {
        Vue.$log.warn('AuthService.logout', err)
      }
    } finally {
      commit(RESET_STATE)
      const logoutReason = params.reason || null
      routes.push({ name: 'login', params: { logoutReason } })
    }
  },
  async [RENEW] ({ commit, dispatch }) {
    try {
      const { data } = await AuthService.renew()
      commit(SET_USER, data)
    } catch (err) {
      dispatch(LOGOUT, { reason: 'timeout' })
    }
  },
  async [REFRESH] ({ commit, dispatch }) {
    try {
      const { data } = await AuthService.getUser()
      commit(SET_USER, data)
    } catch (err) {
      dispatch(LOGOUT, { reason: 'timeout' })
    }
  }
}

export const mutations = {
  [SET_PENDING] (state, pending) {
    state.pending = pending
  },
  [SET_USER] (state, user) {
    state.currentUser = user
    updateAbilities(user)
  },
  [RESET_STATE] () {
    Vue.$log.debug('RESET_STATE')
    for (const f in state) {
      Vue.set(state, f, initialState[f])
    }
  }
}

export const getters = {
  initialRoute (state, { user }) {
    return get(user, 'info.initial_route', 'vertragsdatenbank')
  },
  isLoggedIn (state, { user, session, sessionExpiresAt, isPending }) {
    return !isPending && session && sessionExpiresAt.isValid() && sessionExpiresAt.isAfter(moment())
  },
  isPending (state) {
    return Boolean(state.pending)
  },
  sessionExpiresAt (state, { user }) {
    return moment.utc(get(user, 'session.expires_at', null))
  },
  sessionCreatedAt (state, { user }) {
    return moment.utc(get(user, 'session.created_at', null))
  },
  user (state) {
    return state.currentUser || initialState.currentUser
  },
  session (state, { user }) {
    return user.session
  },
  dataowners (state, { user }) {
    return user.dataowners
  },
  dataownerIds (state, { user }) {
    return map(user.dataowners, 'id')
  },
  dataownerNames (state, { user }) {
    return map(user.dataowners, 'name')
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
