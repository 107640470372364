import router from '@/routes'
import { toPairs } from 'lodash'
import { i18n } from '@/i18n'

const translator = i18n.t.bind(i18n)

function limitString (str, limit) {
  const _limit = limit || 255
  return str.length > _limit ? this.message.substr(0, _limit) + '...' : str
}

export class GenericError {
  static idCounter = 1

  constructor (message, data, original) {
    this.original = original
    this.data = data || {}
    this.message = message || ''
    this.cleared = false
    this.id = GenericError.idCounter++
  }

  get type () {
    return 'generic'
  }

  get explanation () {
    return limitString(translator(this.data.message, null, this.data.params || {}) || 'unknown.error')
  }

  toString () {
    return (
      `GenericError [${this.constructor.type()}]` + limitString(translator(this.data.message) || this.message)
    )
  }
}

export class ResponseError extends GenericError {
  get type () {
    return 'response error'
  }

  get explanation () {
    return limitString(translator(this.data.message, null, this.data.params || {}) || 'server.error')
  }

  toString () {
    const t = this.data.response.statusText
    const c = this.data.response.statusCode
    return `ResponseError [${c} ${t}]` + limitString(this.message)
  }
}

export class NotFoundError extends ResponseError {
  get type () {
    return translator('error.type.notFound')
  }

  get explanation () {
    const url = this.original.response.config.url
    const baseUrl = this.original.response.config.baseURL
    const requestPath = url.substr(baseUrl.length)
    const failedRoute = router.currentRoute
    return `Die Resource <code>${requestPath}</code> für den Anwendungspfad <code>${
      failedRoute.path
    }</code> existiert nicht (mehr).`
  }

  toString () {
    const t = this.data.response.statusText
    const c = this.data.response.statusCode
    return `NotFoundError [${c} ${t}]` + limitString(this.message)
  }
}

export class BadEntitiyError extends ResponseError {
  get type () {
    return translator('error.type.badEntity')
  }

  get explanation () {
    let validationProblems = []
    if (this.data) {
      validationProblems = toPairs(this.data).map((fieldValidationResponse) => {
        const [fieldName, validationError] = fieldValidationResponse
        const validationErrorHtml = validationError.map(translator).join('</li><li>')
        return `<code>${fieldName}</code><ul><li>${validationErrorHtml}</li></ul>`
      })
    }
    return validationProblems.join('')
  }

  toString () {
    const t = this.data.response.statusText
    const c = this.data.response.statusCode
    return `BadEntitiyError [${c} ${t}]` + limitString(this.message)
  }
}

export class CropInUseError extends GenericError {
  get type () {
    return 'crop in use error'
  }

  get explanation () {
    return limitString(translator(this.data.message, null, this.data.params || {}) || 'cropinuse.error')
  }

  toString () {
    return 'CropInUseError ' + limitString(this.message)
  }
}
