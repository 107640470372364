<template lang="pug">
div(class="py-3")
  FixedHeader(fixedClass="is-fixed")
    div.container.errors.fluid
      b-alert(v-for="(e,idx) in errors" :key="e.id"
        show :variant="isNotFoundResponseError(e) ? 'warning' : 'danger'" style="max-height:20vh"
        dismissible @dismissed="dismiss(e.id)")
        b-badge {{e.message}}
        b-badge.mx-1 {{e.id}}
        h5
          span {{e.type}}
        p(v-html="e.explanation")
        pre(v-if="!isEmpty(e.data) && !hideDataOf(e)" style="overflow-y: scroll") {{e.data}}

        template(v-slot:dismiss v-if="!idx")
          span.small.dismiss-all.mr-3(@click="dismissAll()") alle Entfernen
          | ×
</template>

<script>
import { ResponseError, NotFoundError, CropInUseError } from '@/common/utils/error.types.js'
import FixedHeader from 'vue-fixed-header'
import { mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  components: {
    FixedHeader
  },
  computed: { ...mapGetters('errors', ['errors']) },
  methods: {
    ...mapMutations('errors', ['dismiss', 'dismissAll']),
    isEmpty,
    hideDataOf (e) {
      return e instanceof ResponseError || e instanceof CropInUseError
    },
    isNotFoundResponseError (e) {
      return e instanceof NotFoundError
    }
  }
}
</script>

<style lang="scss" scoped>
.dismiss-all{
  font-size: .8rem;
  text-transform: uppercase;
}
</style>
