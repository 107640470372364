import ApiService from './ApiService.js'
import { fromPairs, get } from 'lodash'
import { i18n } from '@/i18n'

let fetchedCountryData
let fetchedCountryGroupData

/**
 * checks if country is in a custom general union of countries (EU, CE + others)
 *
 * used crop tests
 *
 * @param  {Object} country
 * @return {Boolean}
 */
export function isEuropeRegion ({ abbr }) {
  const { EU, CE, alsoInEuropeRegion } = fetchedCountryGroupData
  return EU.indexOf(abbr) > -1 || CE.indexOf(abbr) > -1 || alsoInEuropeRegion.indexOf(abbr) > -1
}

export const metaCountries = [
  {
    id: '*eu*',
    common: 'EU',
    official: 'European Union',
    region: 'Europe',
    abbr: '*EU*',
    de: {
      common: 'EU',
      official: 'Europäische Union'
    },
    inclusionFilter: c => fetchedCountryGroupData.EU.indexOf(c.abbr) > -1,
    exclusionFilter: c => c.abbr === '*EU*' || fetchedCountryGroupData.EU.indexOf(c.abbr) === -1
  },
  {
    id: '*ce*',
    common: 'CE',
    official: 'CE',
    region: 'Europe',
    abbr: '*CE*',
    inclusionFilter: c => fetchedCountryGroupData.CE.indexOf(c.abbr) > -1,
    exclusionFilter: c => c.abbr === '*CE*' || fetchedCountryGroupData.CE.indexOf(c.abbr) === -1
  },
  {
    id: '*ww*',
    common: 'Worldwide',
    official: 'Worldwide',
    region: 'Worldwide',
    abbr: '*WW*',
    de: {
      common: 'Weltweit',
      official: 'Weltweit'
    },
    inclusionFilter: () => true,
    exclusionFilter: c => c.abbr === '*WW*'
  }
]

function attachTranslationToi18n (countries, i18n) {
  // only once
  const translations = countries.concat(metaCountries)

  i18n.mergeLocaleMessage('en', {
    countries: fromPairs(translations.map(c => [c.id, c.common]))
  })

  i18n.mergeLocaleMessage('de', {
    countries: fromPairs(
      translations.map(c => [c.id, get(c, 'de.common', c.common)])
    )
  })

  i18n.mergeLocaleMessage('en', {
    countries: { null: 'none' }
  })

  i18n.mergeLocaleMessage('de', {
    countries: { null: 'keines' }
  })
}

function reduceCountries (countries, { all }) {
  return countries.filter(c => all.indexOf(c.abbr) !== -1)
}

async function fetch () {
  const countryGroupsRequest = ApiService.get('countries/groups')
  const countriesRequest = ApiService.get('countries')

  const [countryGroupsResponse, countriesResponse] =
   await Promise.all([countryGroupsRequest, countriesRequest])

  // cache
  fetchedCountryGroupData = countryGroupsResponse.data

  const relevantCountries = reduceCountries(countriesResponse.data, fetchedCountryGroupData)
  attachTranslationToi18n(relevantCountries, i18n)

  return { data: relevantCountries }
}
export default {
  async get () {
    return fetchedCountryData || (fetchedCountryData = await fetch())
  }
}
