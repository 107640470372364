import ApiService from './ApiService.js'

export default {
  async login (email, password) {
    return ApiService.post('login', { email, password })
  },
  async logout () {
    return ApiService.get('logout')
  },
  async renew () {
    return ApiService.get('login/renew')
  },
  async getUser () {
    return ApiService.get('login/user')
  },
  async changePassword (password) {
    return ApiService.post('login/user/change-password', { password })
  }
}
