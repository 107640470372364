<template lang="pug">
div
  slot
  SlideIn(right :show="showDrawer" :route-name="$router.currentRoute.name")
    ErrorDrawer(v-if="errors.length > 0")
    router-view(v-else name="drawer")
</template>

<script>
import CloseMenuSvg from '@/assets/vector/icons/close.svg'
import SlideIn from '@/components/utils/SlideIn.vue'
import ErrorDrawer from '@/components/common/ErrorDrawer.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    SlideIn,
    ErrorDrawer,
    CloseMenuSvg
  },
  computed: {
    showDrawer () {
      return (this.errors.length > 0) || this.$store.state.drawer.showDrawer
    },
    ...mapGetters('errors', ['errors'])
  }
}
</script>
