import { range, concat } from 'lodash'

export const DEV_MODE = process.env.NODE_ENV === 'development'
export const API_URL = process.env.VUE_APP_API_URL || 'https://suvdb.rwa.digidev.at/api/'
export const API_MOCK_ENABLED = true
export const APP_VERSION = `${process.env.VUE_APP_GIT_BRANCH}-${process.env.VUE_APP_GIT_VERSION}`
export const WS_URL = process.env.VUE_APP_API_WS_URL || 'wss://suvdb.rwa.digidev.at/api/wss'

const main = range(48, 57 + 1)
const numblock = range(96, 105 + 1)

export const keyCodes = {
  DigitAny: concat(main, numblock)
}
