import Vue from 'vue'
import { FirmService } from '@/common/api'
import deepFreeze from '@/common/utils/deepFreeze'
import { now } from '@/common/filters'

import { cloneDeep, omit, merge } from 'lodash'

import {
  SAVE_FIRM,
  LOAD_FIRM,
  TRASH_FIRM,
  UNTRASH_FIRM,
  DELETE_FIRM
} from './actions.type'

import {
  SET_FIRM,
  RESET_FIRM,
  REMOVE_FIRM,
  RESET_STATE
} from './mutations.type'

export const defaultFirm = deepFreeze({
  name: '',
  debitor: '',
  sapName: '',
  ustNumber: '',
  address: { street: '', city: '', postalcode: '', country: 'AT' },

  _meta: { loaded_at: now() }
})

const initialState = deepFreeze({
  current: cloneDeep(defaultFirm),
  lastModifiedFirm: 0
})

export const state = cloneDeep(initialState)

export const actions = {
  async [LOAD_FIRM] ({ commit }, id) {
    Vue.$log.debug('LOAD_FIRM', id)
    const { data } = await FirmService.get(id)

    const firm = {
      ...cloneDeep(defaultFirm),
      ...data,
      _meta: merge(data._meta, { loaded_at: now() })
    }

    commit(SET_FIRM, firm)
    return data
  },
  async [SAVE_FIRM] ({ commit }, firmToSave) {
    Vue.$log.debug('SAVE_FIRM')
    const { data } = await FirmService[firmToSave.id ? 'post' : 'put'](omit(firmToSave, ['_meta']))
    const firm = {
      ...cloneDeep(defaultFirm),
      ...data,
      _meta: merge(data._meta, { loaded_at: now() })
    }
    commit(SET_FIRM, firm)
    return data
  },
  async [TRASH_FIRM] ({ commit }, { id }) {
    Vue.$log.debug('TRASH_FIRM')
    const { data } = await FirmService.trash(id)
    Vue.$log.debug('trashed firm:', id)
    commit(REMOVE_FIRM, id)
    commit(SET_FIRM, data)
  },
  async [UNTRASH_FIRM] ({ commit }, { id }) {
    Vue.$log.debug('UNTRASH_FIRM')
    const { data } = await FirmService.untrash(id)
    Vue.$log.debug('un-trashed firm:', id)
    commit(SET_FIRM, data)
    return data
  },
  async [DELETE_FIRM] ({ commit }, { id }) {
    Vue.$log.debug('DELETE_FIRM')
    await FirmService.delete(id)
    commit(REMOVE_FIRM, id)
    commit(RESET_FIRM)
    Vue.$log.debug('deleted firm:', id)
  }
}

export const mutations = {
  [SET_FIRM] (state, firm) {
    Vue.$log.debug('SET_FIRM', firm.id)
    state.current = firm
    state.lastModifiedFirm = firm.id
  },
  [RESET_FIRM] (state) {
    Vue.$log.debug('RESET_FIRM')
    state.current = cloneDeep(defaultFirm)
  },
  [REMOVE_FIRM] (state, firm) {
    const existingFirmIdx = firm.id
      ? state.all.findIndex(f => f.id === firm.id)
      : -1
    if (existingFirmIdx > -1) state.all.splice(existingFirmIdx, 1)

    state.lastModifiedFirm = firm.id
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, initialState[f])
    }
  }
}

const getters = {
  id (state) {
    return state.current.id
  },
  isDeleted (state) {
    return Boolean(state.current.deleted_at)
  },
  isDirty (state, { isPristine }) {
    return !isPristine
  },
  isPristine (state) {
    return true
  },
  isInValid (state) {
    return !state.current.name
  },
  isValid (state, { isInValid }) {
    return !isInValid
  },
  metaData (state) {
    return state.current._meta
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
