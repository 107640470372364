import Vue from 'vue'
import { UserService } from '@/common/api'
import deepFreeze from '@/common/utils/deepFreeze'

import { isEmailValid } from '@/common/utils/validate'
import { cloneDeep, omit, merge } from 'lodash'

import { LOAD_USER, SAVE_USER, TRASH_USER, UNTRASH_USER, DELETE_USER } from './actions.type'
import { SET_USER, SET_USER_PROPS, SET_USER_INFO_PROPS, SET_USER_DIRTY, SET_USER_PASSWORD, RESET_STATE } from './mutations.type'

export const defaultMeta = deepFreeze({
  dirty: false,
  created_at: null,
  updated_at: null,
  deleted_at: null
})

const initialState = deepFreeze({
  id: 0,
  email: '',
  info: { firstname: '', lastname: '', initial_lang: 'de', initial_route: 'vertragsdatenbank' },
  _meta: cloneDeep(defaultMeta)
})

export const state = cloneDeep(initialState)

export const actions = {
  async [LOAD_USER] ({ commit }, id) {
    Vue.$log.debug('LOAD_USER', id)
    const { data } = await UserService.get(id)
    const user = { ...cloneDeep(initialState), ...data, _meta: merge(cloneDeep(initialState._meta), data._meta) }
    commit(SET_USER, user)
    return user
  },

  async [SAVE_USER] ({ commit }, user) {
    Vue.$log.debug('SAVE_USER')
    const toSave = omit(user, ['info.name', '_meta'])
    const { data } = await UserService[user.id ? 'post' : 'put'](toSave)
    commit(SET_USER, data)
    return data
  },
  async [TRASH_USER] ({ commit }, { id }) {
    Vue.$log.debug('TRASH_USER')
    const { data } = await UserService.trash(id)
    commit(SET_USER, data)
    return data
  },
  async [UNTRASH_USER] ({ commit }, { id }) {
    Vue.$log.debug('UNTRASH_USER')
    const { data } = await UserService.untrash(id)
    commit(SET_USER, data)
    return data
  },
  async [DELETE_USER] ({ commit }, { id }) {
    Vue.$log.debug('DELETE_USER')
    await UserService.delete(id)
    commit(RESET_STATE)
  },
  [RESET_STATE] ({ commit }) {
    commit(RESET_STATE)
  }
}

export const mutations = {
  [SET_USER_DIRTY] (user) {
    user._meta.dirty = true
  },
  [SET_USER] (state, user) {
    for (const f in state) {
      Vue.set(state, f, user[f])
    }
  },
  [SET_USER_PROPS] (state, props) {
    Vue.$log.debug('SET_USER_PROPS', props)
    for (const f in props) {
      Vue.set(state, f, props[f])
    }
    state._meta.dirty = true
  },
  [SET_USER_INFO_PROPS] (state, props) {
    Vue.$log.debug('SET_USER_INFO_PROPS', props)
    for (const f in props) {
      Vue.set(state.info, f, props[f])
    }
    state._meta.dirty = true
  },
  [SET_USER_PASSWORD] (state, pw) {
    Vue.$log.debug('SET_USER_PASSWORD')
    Vue.set(state, 'password', pw)
    state._meta.dirty = true
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, cloneDeep(initialState[f]))
    }
    Vue.delete(state, 'password')
  }
}

const getters = {
  user (state) {
    return state
  },
  info (state) {
    return state.info
  },
  id (state) {
    return state.id
  },
  exists (state) {
    return Boolean(state.id)
  },
  isDeleted (state, { metaData }) {
    return Boolean(metaData.deleted_at)
  },
  isDirty (user, { isPristine }) {
    return !isPristine
  },
  isPristine (user, { exists, metaData }) {
    return !exists || !metaData.dirty
  },
  isInValid (state, { isValid }) {
    return !isValid
  },
  isValid (state) {
    return Boolean(state.email && isEmailValid(state.email))
  },
  metaData (state) {
    return state._meta
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
