<template lang="pug">
b-container#slide-in(:class="[{ 'right' : side , 'show' : show},routeName]")
  slot
</template>

<script>
export default {
  props: {
    right: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String,
      default: ''
    }
  },
  computed: {
    side () {
      return this.right ? 'right' : 'left'
    }
  }
}
</script>

<style lang="scss">
.slide-in-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
}
.icon-link {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-top: 9px;
  padding-bottom: 9px;
  color: black;
  svg {
    width: 25px;
    margin-right: 20px;
    height: 25px;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
  }
}
.pagination {
  svg {
    width: 15px;
    height: auto;
  }
  .next,
  .last {
    display: inline-flex;
    justify-content: flex-end;
  }
  .text-center {
  }
  .first {
    &:before {
      background: #212529;
      content: " ";
      height: 14px;
      width: 1.5px;
      display: inline-block;
      margin-right: 1px;
      margin-top: 0px;
    }
  }
  .last {
    &:after {
      background: #212529;
      content: " ";
      height: 14px;
      width: 1.5px;
      display: inline-block;
      margin-left: 1px;
      margin-top: 1px;
    }
  }
}
.slide-in-navigation {
  .header-row {
    padding: 6px 0;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    > div {
      font-weight: bold;
      font-size: 0.85em;
      line-height: 1.1;
    }
  }
  .link-row {
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    > div {
      font-size: 0.9em;
      line-height: 1.1;
    }
  }
}
</style>
