import ApiService from './ApiService.js'

export default {
  get (id) {
    return ApiService.get('users', id)
  },
  find (params, subresource = '') {
    return ApiService.get('users', subresource, params)
  },
  put (payload, slug = []) {
    return ApiService.put('users', slug, { payload })
  },
  post (payload) {
    return ApiService.post('users', { payload })
  },
  trash (id) {
    return ApiService.trash('users', id)
  },
  untrash (id) {
    return ApiService.untrash('users', id)
  },
  delete (id) {
    return ApiService.delete('users', id)
  }
}
