export default {
  methods: {
    closest (componentName) {
      const predicate = component =>
        component.$options._componentTag === componentName
      return this.closestBy(predicate)
    },
    closestBy (predicate) {
      let component = this
      do if (predicate(component)) return component
      while ((component = component && component.$parent))
    }
  }
}
