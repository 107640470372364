import Vue from 'vue'
import ApiService from './ApiService.js'

export default {
  get (slug, params) {
    return ApiService.get('media', slug, params)
  },
  upload (formdata, onUploadProgress) {
    Vue.$log.debug('MediaService.upload', onUploadProgress)
    return ApiService.post('media', formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  },
  updateMetadata (payload) {
    return ApiService.put('media', `${payload.id}/meta`, { payload })
  },
  replace (formdata, onUploadProgress) {
    Vue.$log.debug('MediaService.replace', onUploadProgress)
    const id = formdata.get('id')
    return ApiService.post(`media/${id}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  },
  remove (id) {
    return ApiService.delete(`media/${id}`)
  }
}
