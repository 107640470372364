import Vue from 'vue'
import deepFreeze from '@/common/utils/deepFreeze'

import { cloneDeep } from 'lodash'

import {
  TOGGLE_DRAWER,
  SHOW_DRAWER,
  CLOSE_DRAWER,
  RESET_STATE
} from './mutations.type'

const initialState = deepFreeze({
  showDrawer: true
})

export const state = cloneDeep(initialState)

export const actions = {}

export const mutations = {
  [TOGGLE_DRAWER] (state) {
    state.showDrawer = !state.showDrawer
  },
  [SHOW_DRAWER] (state) {
    state.showDrawer = true
  },
  [CLOSE_DRAWER] (state) {
    state.showDrawer = false
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, initialState[f])
    }
  }
}

export const getters = {}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
