import Vue from 'vue'
import { CropService } from '@/common/api'
import { reindexObjectArray } from '@/common/utils/'
import deepFreeze from '@/common/utils/deepFreeze'
import { now } from '@/common/filters'

import {
  cloneDeep,
  clone,
  omit,
  isPlainObject,
  isArray, isEmpty,
  merge
} from 'lodash'

import {
  LOAD_CROP,
  SAVE_CROP,
  TRASH_CROP,
  UNTRASH_CROP,
  DELETE_CROP
} from './actions.type'

import {
  SET_CROP,
  SET_CROP_DIRTY,
  SET_CROP_PROPS,
  ADD_CROP_MATURITY,
  REMOVE_CROP_MATURITY,
  SET_CROP_MATURITY,
  ADD_CROP_BRAND,
  REMOVE_CROP_BRAND,
  SET_CROP_BRAND,
  ADD_CROP_TEST,
  REMOVE_CROP_TEST,
  SET_CROP_TEST,
  ADD_CROP_PRETEST,
  REMOVE_CROP_PRETEST,
  SET_CROP_PRETEST,
  ADD_CROP_LINETEST,
  REMOVE_CROP_LINETEST,
  SET_CROP_LINETEST,
  ADD_CROP_DEMO,
  REMOVE_CROP_DEMO,
  SET_CROP_DEMO,
  RESET_STATE,
  RESET_OWNERSHIP,
  RESET_CULTUREUSAGE
} from './mutations.type'

function createAddToFn (container, defaultElem) {
  const stateName = String(container)
  return (state, newElem = {}) => {
    let position = 0
    if (newElem && 'position' in newElem) {
      position = newElem.position
      newElem = newElem.newElem
    }
    const dClone = cloneDeep(defaultElem)
    const toInsert = Object.assign({}, dClone, newElem, {
      id: position > 0 ? position : state[stateName].length
    })
    if (position > 0) {
      const elements = state[stateName].slice()
      elements.splice(position, 0, toInsert)
      reindexObjectArray(elements)
      state[stateName] = elements
    } else state[stateName].push(toInsert)

    state._meta.dirty = true
  }
}

function createRemoveFromFn (container) {
  const stateName = String(container)
  return (state, { id }) => {
    const entries = state[stateName]
    entries.splice(entries.findIndex(t => t.id === id), 1)
    reindexObjectArray(entries)
    state._meta.dirty = true
  }
}

function createSetFn (container) {
  const stateName = String(container)
  return (state, elem) => {
    const id = elem.id

    const entries = state[stateName]

    const toUpdateIndex = entries.findIndex(t => t.id === id)

    const toUpdate = entries[toUpdateIndex]
    entries.splice(toUpdateIndex, 1, {
      ...toUpdate,
      ...elem
    })
    state._meta.dirty = true
  }
}

export const defaultTestStatus = deepFreeze({
  year: null,
  statusWp: '',
  costWp: null,
  splitWp: null,
  splitterWp: null,
  maturityWp: null,
  statusDus: '',
  costDus: null,
  splitDus: null,
  splitterDus: null
})

export const defaultTestRegistration = deepFreeze({
  date: null,
  validUntil: null,
  extensionRwa: null,
  extensionBreeder: null,
  extensionAges: null,
  extensionFunder: '',
  expireDate: null,
  deletionDate: null,
  upovPictures: null,
  testdocument: 0,
  bescheid: 0,
  documents: [],
  oecddocument: 0,
  tgdocument: 0,
  appldocument: 0
})

export const defaultTestOrangeLabel = deepFreeze({
  amount: null,
  unit: '',
  country: '',
  cost: null
})

export const defaultTest = deepFreeze({
  eu: false,
  requestedBy: 0,
  country: '',
  testgroup: '',
  maturityGroup: '',
  year: null,

  amtlicheSortenNr: '',
  antragSortenzulassungDate: null,
  antragSortenbezeichnungDate: null,
  antragRevertDate: null,

  oecdDate: null,
  euDate: null,
  applicationFee: null,
  yearlyFee: null,

  annotation: '',
  externalPath: '',

  sortenzulassungdocument: 0,
  technischerfragebogendocument: 0,
  sortenbezeichnungdocument: 0,
  zuruekziehungdocument: 0,
  upovdusregisterdocument: 0,

  upovDusRegister: null,
  hasOrangeLabel: null,
  orangeLabelFunder: 0,
  applicationDate: null,
  sellRightDate: null,
  orangelabels: [clone(defaultTestOrangeLabel)],
  teststatus: [clone(defaultTestStatus)],
  registration: cloneDeep(defaultTestRegistration)
})

export const defaultPreTest = deepFreeze({
  year: null,
  country: null,
  testseries: '',
  partner: 0,
  institut: 0,
  externalPath: '',
  annotation: '',
  documents: [],
  rightToSale: null
})

export function isPreTestEmpty (p) {
  return Object.keys(defaultPreTest)
    .every((k) => isArray(p[k]) ? isEmpty(p[k]) : !p[k])
}

export function isPreTestValid ({ year, country }) {
  return Boolean(year) && Boolean(country)
}

export const defaultLineTest = deepFreeze({
  line: '',
  country: '',
  cost: null,
  registrationDate: '',
  euDate: '',
  applicationFee: null,
  yearlyFee: null,
  upovDusRegister: null,
  technischerfragebogendocument: 0,
  upovdusregisterdocument: 0,
  registrierungsbericht: 0,
  tgdocument: 0,
  appldocument: 0,
  annotation: ''
})

export const defaultDemo = deepFreeze({
  year: null,
  country: null,
  partner: 0,
  externalPath: '',
  annotation: ''
})

export const defaultMaturity = deepFreeze({
  country: null,
  number: ''
})

export const defaultBrand = deepFreeze({
  country: null,
  name: ''
})

export const defaultMeta = deepFreeze({
  dirty: false,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  modificator: null,
  creator: null,
  data_owned_by: {},
  data_shared_with: []
})

const initialState = deepFreeze({
  id: 0,
  breeder: 0,

  // these have to start null
  name: null,
  code: null,
  hybrid: null,

  culture: '',
  synonym: '',
  originalbreeder: 0,
  owner: 0,
  ownerContactName: '',
  ownerContactEmail: '',
  sapcode: null,
  cornType: '',
  usage: [],
  zucht: '',
  crossMaterial: '',
  pedigree: { mother: [], father: [] },

  preTestsComment: '',
  testsComment: '',
  lineTestsComment: '',
  demosComment: '',

  pretests: [{ id: 0, ...cloneDeep(defaultPreTest) }],
  tests: [{ id: 0, ...cloneDeep(defaultTest) }],
  linetests: [{ id: 0, ...cloneDeep(defaultLineTest) }],
  demos: [{ id: 0, ...cloneDeep(defaultDemo) }],

  properties: {},
  maturities: [{ id: 0, ...cloneDeep(defaultMaturity) }],
  brands: [{ id: 0, ...cloneDeep(defaultBrand) }],

  slogan: null,
  principles: [null, null, null],
  praxisTip: null,

  marketingDate: null,

  approvedFor: null,

  _meta: cloneDeep(defaultMeta)
})

export const state = cloneDeep(initialState)

export const actions = {
  async [LOAD_CROP] ({ commit }, id) {
    Vue.$log.debug('LOAD_CROP', id)
    const { data } = await CropService.get(id)
    const crop = {
      ...cloneDeep(initialState),
      ...data,
      _meta: merge(cloneDeep(initialState._meta), data._meta, { loaded_at: now() })
    }

    commit(SET_CROP, crop)

    return crop
  },
  async [SAVE_CROP] ({ commit }, payload) {
    Vue.$log.debug('SAVE_CROP', payload.id)
    const { data } = await CropService[payload.id ? 'post' : 'put'](omit(payload, ['_meta']))
    const crop = {
      ...cloneDeep(initialState),
      ...data,
      _meta: merge(cloneDeep(initialState._meta), data._meta, { loaded_at: now() })
    }

    commit(SET_CROP, crop)
    return crop
  },
  async  [TRASH_CROP] ({ commit }, { id }) {
    Vue.$log.debug('TRASH_CROP')
    const { data } = await CropService.trash(id)
    commit(SET_CROP, data)
    Vue.$log.debug('trashed crop:', id)
  },
  async [UNTRASH_CROP] ({ commit }, { id }) {
    Vue.$log.debug('UNTRASH_CROP')
    const { data } = await CropService.untrash(id)
    commit(SET_CROP, data)
    Vue.$log.debug('untrashed crop:', id)
  },
  async [DELETE_CROP] ({ commit }, { id }) {
    Vue.$log.debug('DELETE_CROP')
    await CropService.delete(id)
    commit(RESET_STATE)
    Vue.$log.debug('deleted crop:', id)
  }
}

export const mutations = {
  [SET_CROP_DIRTY] (state) {
    state._meta.dirty = true
  },
  [ADD_CROP_TEST]: createAddToFn('tests', defaultTest),
  [REMOVE_CROP_TEST]: createRemoveFromFn('tests'),
  [SET_CROP_TEST]: createSetFn('tests'),

  [ADD_CROP_PRETEST]: createAddToFn('pretests', defaultPreTest),
  [REMOVE_CROP_PRETEST]: createRemoveFromFn('pretests'),
  [SET_CROP_PRETEST]: createSetFn('pretests'),

  [ADD_CROP_LINETEST]: createAddToFn('linetests', defaultLineTest),
  [REMOVE_CROP_LINETEST]: createRemoveFromFn('linetests'),
  [SET_CROP_LINETEST]: createSetFn('linetests'),

  [ADD_CROP_DEMO]: createAddToFn('demos', defaultDemo),
  [REMOVE_CROP_DEMO]: createRemoveFromFn('demos'),
  [SET_CROP_DEMO]: createSetFn('demos'),

  [ADD_CROP_MATURITY]: createAddToFn('maturities', defaultMaturity),
  [REMOVE_CROP_MATURITY]: createRemoveFromFn('maturities'),
  [SET_CROP_MATURITY]: createSetFn('maturities'),

  [ADD_CROP_BRAND]: createAddToFn('brands', defaultBrand),
  [REMOVE_CROP_BRAND]: createRemoveFromFn('brands'),
  [SET_CROP_BRAND]: createSetFn('brands'),

  [SET_CROP] (state, crop) {
    Vue.$log.debug('SET_CROP')

    if (!crop.maturities || !isArray(crop.maturities) || !crop.maturities.length) {
      crop.maturities = [{ id: 0, ...cloneDeep(defaultMaturity) }]
    }

    if (!crop.brands || !isArray(crop.brands) || !crop.brands.length) {
      crop.brands = [{ id: 0, ...cloneDeep(defaultBrand) }]
    }

    reindexObjectArray(crop.tests, defaultTest)
    reindexObjectArray(crop.pretests, defaultPreTest)
    reindexObjectArray(crop.linetests, defaultLineTest)
    reindexObjectArray(crop.demos, defaultDemo)
    reindexObjectArray(crop.maturities, defaultMaturity)
    reindexObjectArray(crop.brands, defaultBrand)

    if ((crop.properties && isArray(crop.properties)) || !isPlainObject(crop.properties)) {
      crop.properties = {}
    }

    for (const f in state) {
      Vue.set(state, f, crop[f])
    }
  },
  [SET_CROP_PROPS] (state, crop) {
    for (const f in crop) {
      Vue.set(state, f, crop[f])
    }
    state._meta.dirty = true
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, cloneDeep(initialState[f]))
    }
  },
  [RESET_OWNERSHIP] (state) {
    [
      'owner',
      'breeder',
      'originalbreeder',
      'ownerContactName',
      'ownerContactEmail'
    ].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  },
  [RESET_CULTUREUSAGE] (state) {
    [
      'id',
      'culture',
      'name',
      'code',
      'synonym',
      'hybrid',
      'sapcode',
      'cornType',
      'usage',
      'zucht',
      'pedigree'
    ].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  }
}

const getters = {
  id (state) {
    return state.id || null
  },
  crop (state) {
    return state
  },
  culture (state) {
    return state.culture || ''
  },
  tests (state) {
    return state.tests || []
  },
  pretests (state) {
    return state.pretests || []
  },
  linetests (state) {
    return state.linetests || []
  },
  demos (state) {
    return state.demos || []
  },
  maturities (state) {
    return state.maturities || []
  },
  brands (state) {
    return state.brands || []
  },
  hybrid (state) {
    return state.hybrid || false
  },
  exists (state) {
    return Boolean(state.id)
  },
  canBeCopied (crop, { exists }) {
    return exists
  },
  isDeleted (crop, { exists, metaData }) {
    return exists && Boolean(metaData.deleted_at)
  },
  isApproved (crop, { exists }) {
    return exists && Boolean(crop.approvedFor)
  },
  isDirty (crop, { isPristine }) {
    return !isPristine
  },
  isPristine (crop, { exists, metaData }) {
    return !exists || !metaData.dirty
  },
  isInValid ({ culture, name, code, pretests }) {
    const preTestsValid = pretests.filter(p => !isPreTestEmpty(p)).every(isPreTestValid)
    const missingCulture = isEmpty(culture)
    const missingNameAndCode = isEmpty(name) && isEmpty(code)

    return missingCulture || missingNameAndCode || !preTestsValid
  },
  isValid (crop, { isInValid }) {
    return !isInValid
  },
  metaData (state) {
    return state._meta
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
