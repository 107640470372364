<template lang="pug">
div
  TheDrawer(v-if='isLoggedIn')
    TheHome
  Login(v-else :visible="!isLoggedIn && !isPending" key="the-login")
</template>

<script>
import Login from '@/components/common/Login.vue'
import TheHome from '@/components/common/TheHome.vue'
import TheDrawer from '@/components/common/TheDrawer.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Suv',
  components: {
    TheHome,
    TheDrawer,
    Login
  },
  computed: mapGetters('auth', ['isLoggedIn', 'isPending'])
}
</script>

<style lang="scss" src="@/styles/global.scss"></style>
